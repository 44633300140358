
import { defineComponent, ref, onMounted, computed } from 'vue';
import X from '@/components/web3/x.vue';
import useTokens from '../../composables/PolarisFinance/useTokens';
import { useRoute } from 'vue-router';
import useWeb3 from '@/services/web3/useWeb3';
import useBonds from '../../composables/PolarisFinance/useBonds';
import { parseFixed } from '@ethersproject/bignumber';
import useTransactions from '@/composables/useTransactions';
import { TransactionResponse } from '@ethersproject/providers';
import useEthers from '../../composables/useEthers';

/**
 * STATE
 */
const textInput = ref<HTMLInputElement>();
/**
 * LIFECYCLE
 */
onMounted(() => {
  textInput.value?.focus();
});
export default defineComponent({
  components: {
    X,
  },
  props: {
    isVisible: Boolean,
    purchaseBol: Boolean,
    balance: { type: String, default: '0' },
    name: { String, default: '' },
    account: { String, default: '' },
    sunriseName: { String, default: '' },
  },
  setup(props, { emit }) {
    const { getProvider } = useWeb3();

    const route = useRoute();
    const { addTransaction } = useTransactions();
    const { txListener } = useEthers();

    const txHandler = (tx: TransactionResponse): void => {
      addTransaction({
        id: tx.hash,
        type: 'tx',
        action: 'stake',
        summary: 'deposit for sunrise',
      });
    };

    return {
      getProvider,
      txListener,
      txHandler,
      emit,
    };
  },

  data() {
    return {
      inputValue: '0',
    };
  },
  methods: {
    maxBalance() {
      this.inputValue = this.balance;
    },
    async purchase(amount: string) {
      const formatedAmount = parseFixed(amount, 18);
      const { purchase } = useBonds(this.account, this.sunriseName);
      const tx = await purchase(formatedAmount, this.getProvider());
      this.txHandler(tx);
      this.txListener(tx, {
        onTxConfirmed: () => {
          this.emit('close');
          this.emit('update');
        },
        onTxFailed: () => {},
      });
    },

    async redeem(amount: string) {
      const formatedAmount = parseFixed(amount, 18);
      const { redeem } = useBonds(this.account, this.sunriseName);
      const tx = await redeem(formatedAmount, this.getProvider());
      this.txHandler(tx);
      this.txListener(tx, {
        onTxConfirmed: () => {
          this.emit('close');
          this.emit('update');
        },
        onTxFailed: () => {},
      });
    },
  },

  emits: ['close', 'update'],
});
