
import {
  ref,
  computed,
  defineComponent,
  onMounted,
  reactive,
  toRefs,
} from 'vue';
import { useRoute } from 'vue-router';
import { sunriseDefinitions } from './config.js';
import useBreakpoints from '@/composables/useBreakpoints';

import polarImg from './polar.svg';
import orbitalImg from './orbital.svg';
import uspImg from './usp.svg';
import ethernalImg from './ethernal.svg';
import binarisImg from './binaris.svg';
import tripolarImg from './tripolar.svg';

import PbondImg from './Pbond.svg';
import EbondImg from './Ebond.svg';
import ObondImg from './Obond.svg';
import USPbondImg from './USPbond.svg';
import BbondImg from './Bbond.svg';

import useWeb3 from '@/services/web3/useWeb3';

import useBonds from '@/composables/PolarisFinance/useBonds';
import useTreasury from '@/composables/PolarisFinance/useTreasury';
import { BigNumber } from 'ethers';
import BondModal from './BondModal.vue';
import useEthers from '../../composables/useEthers';
import useTransactions from '@/composables/useTransactions';
import { TransactionResponse } from '@ethersproject/providers';

interface PoolPageData {
  id: string;
}

export default defineComponent({
  components: { BondModal },

  setup() {
    const { addTransaction } = useTransactions();
    const { txListener } = useEthers();

    const txHandler = (tx: TransactionResponse): void => {
      addTransaction({
        id: tx.hash,
        type: 'tx',
        action: 'approve',
        summary: 'deposit for sunrise',
      });
    };
    const route = useRoute();
    const route_id = route.params.id;
    const sunriseName = route_id.toString();

    const { isMobile, isDesktop } = useBreakpoints();

    const { account, getProvider } = useWeb3();

    const logo = {
      polar: polarImg,
      orbital: orbitalImg,
      usp: uspImg,
      ethernal: ethernalImg,
      binaris: binarisImg,
    };

    const bondLogo = {
      polar: PbondImg,
      orbital: ObondImg,
      usp: USPbondImg,
      ethernal: EbondImg,
      binaris: BbondImg,
    };

    const data = reactive<PoolPageData>({
      id: route.params.id as string,
    });

    const sunrise = computed(() => {
      for (let sunrise of Object.values(sunriseDefinitions)) {
        if (sunrise.name == data.id) return sunrise;
      }
      return sunriseDefinitions.polar;
    });

    const isPurchaseBondModalVisible = ref(false);
    const togglePurchaseBondModal = () => {
      isPurchaseBondModalVisible.value = !isPurchaseBondModalVisible.value;
    };

    const isRedeemBondModalVisible = ref(false);
    const toggleRedeemBondModal = () => {
      isRedeemBondModalVisible.value = !isRedeemBondModalVisible.value;
    };

    return {
      // data
      ...toRefs(data),
      isMobile,
      isDesktop,
      logo,
      sunriseName,

      // computed
      sunrise,

      getProvider,
      account,
      isPurchaseBondModalVisible,
      togglePurchaseBondModal,
      txHandler,
      txListener,
      isRedeemBondModalVisible,
      toggleRedeemBondModal,
      bondLogo,
    };
  },
  methods: {
    async approve() {
      const { approvePurchase } = useBonds(this.account, this.sunriseName);

      const tx = await approvePurchase(this.getProvider());
      this.txHandler(tx);
      this.txListener(tx, {
        onTxConfirmed: () => {
          this.render();
        },
        onTxFailed: () => {},
      });
    },

    async render() {
      const { isApprovedPurchase, getTokenBalance, getBondBalance } = useBonds(
        this.account,
        this.sunriseName
      );
      const { getCurrentTWAP, getLastEpochTWAP } = useTreasury(
        this.sunriseName
      );
      if (this.account === '') {
        [this.previousEpochTwap, this.currentTwap] = await Promise.all([
          getLastEpochTWAP(),
          getCurrentTWAP(),
        ]);
        const twap = parseFloat(this.currentTwap);

        let bondPrice = 0;
        if (twap >= 1.01) {
          bondPrice = 1 + (twap - 1) * 0.7;
        } else {
          bondPrice = twap;
        }
        this.bondPrice = bondPrice.toString();
      } else {
        [
          this.previousEpochTwap,
          this.currentTwap,
          this.approved,
          this.tokenBalance,
          this.bondBalance,
        ] = await Promise.all([
          getLastEpochTWAP(),
          getCurrentTWAP(),
          isApprovedPurchase(),
          getTokenBalance(),
          getBondBalance(),
        ]);
        const twap = parseFloat(this.currentTwap);

        let bondPrice = 0;
        if (twap >= 1.01) {
          bondPrice = 1 + (twap - 1) * 0.7;
        } else {
          bondPrice = twap;
        }
        this.bondPrice = bondPrice.toString();
      }
      this.redeemEnabled = parseFloat(this.previousEpochTwap) > 1.01;
    },
  },
  async created() {
    await this.render();
  },

  data() {
    return {
      approved: false,
      redeemEnabled: false,
      previousEpochTwap: '-',
      currentTwap: '-',
      bondPrice: '-',
      tokenBalance: '-',
      bondBalance: '-',
    };
  },
  watch: {
    async account(newAccount, oldAccount) {
      await this.render();
    },
  },
});
