import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, withCtx as _withCtx, createBlock as _createBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-6677f606")
const _hoisted_1 = { class: "p-[12px]" }
const _hoisted_2 = { class: "header px-[12px]" }
const _hoisted_3 = {
  key: 0,
  class: "title text-white"
}
const _hoisted_4 = {
  key: 1,
  class: "title text-white"
}
const _hoisted_5 = { class: "grid justify-items-start pt-[24px]" }
const _hoisted_6 = { class: "pl-[12px] text-[14px] font-medium leading-[18px] text-pink-third" }
const _hoisted_7 = { class: "input-container mt-[8px] flex w-full justify-between rounded-[16px] bg-[#2E2433] p-[12px]" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_X = _resolveComponent("X")!
  const _component_BalModal = _resolveComponent("BalModal")!

  return (_openBlock(), _createBlock(_component_BalModal, {
    show: _ctx.isVisible,
    noPad: "",
    onClose: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('close')))
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_ctx.purchaseBol)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, " Purchase " + _toDisplayString(_ctx.name), 1))
            : (_openBlock(), _createElementBlock("div", _hoisted_4, "Redeem " + _toDisplayString(_ctx.name), 1)),
          _createVNode(_component_X, {
            class: "pt-[4px]",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
          })
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("span", _hoisted_6, "Available: " + _toDisplayString(_ctx.balance), 1),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("button", {
              class: "button-style my-[1.5px] rounded-[10px] px-[12px] py-[4px] font-semibold leading-[20px] text-white",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.maxBalance && _ctx.maxBalance(...args)))
            }, " MAX "),
            _withDirectives(_createElementVNode("input", {
              ref: "textInput",
              placeholder: "0.0",
              class: "bg-transparent text-right text-[24px] font-medium leading-[31px]",
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.inputValue = $event))
            }, null, 512), [
              [_vModelText, _ctx.inputValue]
            ])
          ]),
          _createElementVNode("button", {
            class: "button-style mt-[12px] h-[44px] w-full rounded-[16px] text-white",
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.purchaseBol ? _ctx.purchase(_ctx.inputValue) : _ctx.redeem(_ctx.inputValue)))
          }, " Confirm ")
        ])
      ])
    ]),
    _: 1
  }, 8, ["show"]))
}